@import '../../../styles/variables';

.FooterContainer {
  background-color: var(--footerBackgroundColor);
  background-image: var(--footerBackgroundImage);
  border-top: 1px solid var(--gray);
  color: var(--black);
  max-width: 100%;

  .FooterBrandContainer {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    margin: 0px auto;
    padding-top: 48px;
    padding-bottom: 72px;

    @include for-desktop-only {
      .FooterBrandColumnItem {
        width: 50%;
      }
    }
    @include for-mobile-and-tablet {
      .FooterBrandColumnItem {
        width: 90%;
      }
    }

    .FooterColumnContainer {
      .FooterColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &.FooterBrandColumn {
          .FooterLogoContainer {
            margin-bottom: 16px;

            .FooterLogoLink {
              display: block;
              width: 100%;
              max-width: 100%;
            }

            .FooterLogoImage {
              object-fit: contain;
              height: 48px;
              max-width: 148px;
            }
          }

          .FooterBrandDescription {
            font-size: 17px;
            text-align: center;
          }

          .FooterBrandLinksContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            margin-top: 12px;
            opacity: 0.54;

            .FooterBrandLinkItem {
              color: #000;
              font-family: Avenir Next;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px; 
            }
          }

          .FooterBrandsCopyrightContainer {
            color: #000;
            text-align: center;

            font-family: Avenir Next;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.5px; 
          }
        }
      }
    }
  }

  .FooterColumns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    max-width: 1600px;
    margin: 0px auto;
    padding-top: 48px;
    padding-bottom: 72px;

    .FooterColumnContainer {
      .FooterColumn {
        display: flex;
        flex-direction: column;

        &.FooterBrandColumn {
          .FooterLogoContainer {
            margin-bottom: 16px;

            .FooterLogoLink {
              display: block;
              width: 100%;
              max-width: 100%;
            }

            .FooterLogoImage {
              object-fit: contain;
              height: 48px;
              max-width: 148px;
            }
          }

          .FooterBrandDescription {
            font-size: 17px;
          }

          .FooterBrandContacts {
            margin-top: 36px;
            font-size: 18px;

            .FooterBrandContact {
              &:not(:first-child) {
                margin-top: 18px;
              }

              .FooterBrandContactTitle {
                font-weight: 700;
              }

              .FooterBrandContactEmail,
              .FooterBrandContactNumber,
              .FooterBrandContactAddress {
                margin-top: 12px;
              }

              .FooterBrandContactEmail,
              .FooterBrandContactNumber {
                &:hover {
                  color: var(--green);
                }
              }

              .FooterBrandContactAddress {
                white-space: pre-line;
              }
            }
          }
        }

        &.FooterLinksColumn {
          margin-top: 24px;

          .FooterLinksHeader {
            color: var(--green);
            font-weight: 700;
            font-size: 20px;
          }

          .FooterLinkContainer {
            .FooterLink {
              display: block;
              margin-top: 16px;
              font-size: 18px;
              color: var(--black);
              opacity: 0.54;
              width: auto;
              max-width: fit-content;

              &:hover {
                color: var(--green);
                opacity: 1;
              }
            }
          }
        }
      }

      &.FooterBrandColumnContainer {
        padding-right: 18px;
      }

      &.FooterLinksColumnContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        padding-left: 18px;

        .FooterLinksColumn {
          padding: 18px;

          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            padding-right: 0px;
          }
        }
      }
    }
  }

  @include for-desktop-only {
    .FooterColumns {
      .FooterColumnContainer {
        .FooterColumn {
          &.FooterLinksColumn {
            margin-top: 18px;
          }
        }

        &.FooterBrandColumnContainer {
          width: 30%;

          .FooterColumn {
            &.FooterBrandColumn {
              .FooterLogoImage {
                height: 48px;
                max-width: 148px;
                margin-left: -4px;
              }
            }
          }
        }

        &.FooterLinksColumnContainer {
          width: 70%;

          .FooterLinksColumn {
            width: 25%;
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  @include for-tablet-only {
    .FooterColumns {
      .FooterColumnContainer {
        &.FooterBrandColumnContainer {
          width: 40%;

          .FooterColumn {
            &.FooterBrandColumn {
              .FooterLogoImage {
                height: 42px;
                max-width: 128px;
                margin-left: -4px;
              }
            }
          }
        }

        &.FooterLinksColumnContainer {
          width: 60%;

          .FooterLinksColumn {
            width: 50%;

            &:nth-child(1),
            &:nth-child(3) {
              padding-left: 0px;
            }

            &:nth-child(2),
            &:nth-child(4) {
              padding-right: 0px;
            }

            &:nth-child(1),
            &:nth-child(2) {
              padding-top: 0px;
            }

            &:nth-child(3),
            &:nth-child(4) {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  @include for-mobile-only {
    .FooterColumns {
      flex-direction: column;

      .FooterColumnContainer {
        &.FooterBrandColumnContainer {
          width: 100%;
          padding-right: 0px;

          .FooterColumn {
            &.FooterBrandColumn {
              .FooterLogoContainer {
                .FooterLogoImage {
                  height: 42px;
                  max-width: 128px;
                  margin-left: -4px;
                }
              }
            }
          }
        }

        &.FooterLinksColumnContainer {
          width: 100%;
          padding-left: 0px;

          .FooterLinksColumn {
            width: 100%;
            padding: 0px;
            margin-top: 48px;
          }
        }
      }
    }
  }

  // @media (prefers-color-scheme: dark) {
  //   color: var(--white);

  //   .FooterColumns {
  //     .FooterColumnContainer {
  //       .FooterColumn {
  //         &.FooterLinksColumn {
  //           .FooterLinkContainer {
  //             .FooterLink {
  //               color: var(--white);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
