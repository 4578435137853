@import '../../../styles/variables';

:export {
  variables: {
    largeDesktopMediaQueryMinWidth: $largeDesktopMediaQueryMinWidth;
    desktopMediaQueryMinWidth: $desktopMediaQueryMinWidth;
    tabletMediaQueryMaxWidth: $tabletMediaQueryMaxWidth;
    tabletMediaQueryMinWidth: $tabletMediaQueryMinWidth;
    mobileMediaQueryMaxWidth: $mobileMediaQueryMaxWidth;
  }
}

.HeaderContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 20;
  background-color: var(--background);
  padding: 0px;
  height: $headerHeight;
  transition: height 0.2s ease;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    max-width: $veryLargeDesktopMediaQueryMaxWidth;
    margin: 0px auto;

    .HeaderLogo {
      padding: 0px 0px 0px 96px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .HeaderLogoLink {
        display: flex;
        outline: none;
      }

      .HeaderLogoImage {
        object-fit: contain;
        outline: none;
        height: 48px;
        max-width: 148px;
        margin-top: 3px;
        margin-left: -3px;
      }
    }

    .HeaderMenu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0px 60px 0px 0px;

      .HeaderMenuItemList {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        margin: 0px;
        padding: 0px;
        list-style-type: none;

        .HeaderMenuItemContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          padding: 12px 36px;

          .HeaderMenuBookDemoButton {
            border: none;

            &:hover {
              background: white;
              color: var(--green);
            }
          }

          .HeaderMenuItem {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 18px;
            color: var(--black);
            transition: display 0.2s ease;
            position: relative;
            height: 100%;

            .HeaderMenuLink {
              text-decoration: none;
              outline: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 100%;

              .HeaderMenuLinkText {
                position: relative;
              }
            }

            .HeaderSubMenuDropdownIcon {
              margin-left: 8px;
              cursor: pointer;
              transition: transform 0.2s ease;
            }
          }

          .HeaderSubMenuDropdown {
            display: flex;
            opacity: 0;
            visibility: hidden;
            height: 0px;
            width: 0px;
            padding: 0px;
            transition: opacity 0.2s ease;
          }

          &:hover,
          &:focus {
            cursor: pointer;

            .HeaderMenuLink {
              color: var(--green);
            }

            .HeaderSubMenuDropdownIcon {
              transform: rotateZ(180deg);

              path {
                stroke: var(--green);
              }
            }

            .HeaderSubMenuDropdown {
              opacity: 1;
              visibility: visible;
              height: auto;
              width: auto;
              min-width: 100%;
              background-color: var(--background);
              border-top: 1px solid var(--gray);
              border-bottom: 1px solid var(--gray);
              position: fixed;
              top: $headerHeight;
              left: 0px;
              right: 0px;
              padding: 36px;
            }
          }
        }
      }

      .HeaderMenuIconContainer {
        display: none;
      }
    }
  }

  &.HeaderFixed {
    border-bottom: 1px solid var(--gray);
    background-color: var(--background);
    height: $headerCollapsedHeight;

    .HeaderMenu {
      .HeaderMenuItemList {
        .HeaderMenuItemContainer {

          &:hover,
          &:focus {
            .HeaderSubMenuDropdown {
              top: $headerCollapsedHeight;
              border-top: none;
            }
          }
        }
      }
    }
  }

  .HeaderMobileMenu {
    opacity: 0;
    visibility: hidden;
    height: 0px;
    width: 0px;
    transition: opacity 0.2s ease;
  }

  @include for-desktop-only {
    .Header {
      .HeaderLogo {
        flex: 0 1 auto;
        padding-right: 96px;
      }

      .HeaderMenu {
        flex: 1 0 auto;

        .HeaderMenuItemList {
          flex: 1 0 auto;

          .HeaderMenuItemContainer {
            &:first-child {
              justify-content: flex-end;
            }

            &.HeaderSubMenuItemContainer {
              &:not(.HeaderSubMenuDropdownOpen) {
                .HeaderSubMenuDropdown {
                  // TODO: fix this, it's a hack for ipad portrait view
                  display: none;
                }
              }

              &.HeaderSubMenuDropdownOpen {
                .HeaderSubMenuDropdown {
                  cursor: auto;
                }
              }
            }
          }
        }
      }
    }

    &.HeaderFixed {
      .HeaderMenu {
        .HeaderMenuItemList {
          .HeaderMenuItemContainer {
            &.HeaderSubMenuItemContainer {
              &.HeaderSubMenuDropdownOpen {
                &:first-child {
                  flex: 1 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }

  @include for-mobile-and-tablet {
    .Header {
      .HeaderLogo {
        padding-left: 36px;
      }

      .HeaderMenu {
        padding-right: 36px;

        .HeaderMenuItemList {
          display: none;
        }

        .HeaderMenuIconContainer {
          display: flex;

          .HeaderMenuIcon {
            width: 26px;
            height: 20px;
            position: relative;
            transform: rotate(0deg);
            transition: all 0.5s ease-in-out;
            cursor: pointer;

            span {
              display: block;
              position: absolute;
              height: 4px;
              width: 100%;
              background-color: var(--black);
              border-radius: 8px;
              opacity: 1;
              left: 0;
              transform: rotate(0deg);
              transition: all 0.25s ease-in-out;
            }

            span:nth-child(1) {
              top: 0px;
              transform-origin: left center;
            }

            span:nth-child(2) {
              top: 8px;
              transform-origin: left center;
            }

            span:nth-child(3) {
              top: 16px;
              transform-origin: left center;
            }

            &.HeaderMenuIconOpen {
              span:nth-child(1) {
                transform: rotate(45deg);
                top: 0px;
                left: 4px;
              }

              span:nth-child(2) {
                width: 0%;
                opacity: 0;
              }

              span:nth-child(3) {
                transform: rotate(-45deg);
                top: 19px;
                left: 4px;
              }
            }
          }
        }
      }
    }

    &.HeaderMenuOpen {
      .Header {
        height: $headerCollapsedHeight;
        border-bottom: 1px solid var(--gray);
      }

      .HeaderMobileMenu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        opacity: 1;
        visibility: visible;
        width: 100%;
        background-color: var(--background);
        padding: 0px 36px 36px 36px;
        overflow: auto;

        .HeaderMenuItemList {
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          .HeaderMenuItemContainer {
            border-bottom: 1px solid var(--gray);

            .HeaderMenuItem {
              display: flex;
              justify-content: stretch;
              align-items: center;
              width: 100%;
              height: $headerCollapsedHeight;
              cursor: pointer;

              .HeaderSubMenuDropdownIcon {
                margin-left: 12px;
                cursor: pointer;
                transition: transform 0.2s ease;
              }

              &:hover,
              &:focus {
                .HeaderMenuLink {
                  color: var(--green);
                }

                .HeaderSubMenuDropdownIcon {
                  path {
                    stroke: var(--green);
                  }
                }
              }
            }

            .HeaderSubMenuDropdown {
              display: none;
              opacity: 0;
              visibility: hidden;
              height: 0px;
              width: 0px;
              transition: opacity 0.2s ease;
            }

            &.HeaderSubMenuDropdownOpen {
              padding-bottom: 24px;
              border-bottom: 1px solid var(--gray);

              .HeaderMenuItem {
                border-bottom: none;

                .HeaderSubMenuDropdownIcon {
                  transform: rotateZ(180deg);
                }

                .HeaderMenuLink {
                  color: var(--green);
                }

                .HeaderSubMenuDropdownIcon {
                  path {
                    stroke: var(--green);
                  }
                }
              }

              .HeaderSubMenuDropdown {
                display: flex;
                opacity: 1;
                visibility: visible;
                height: auto;
                width: auto;
              }
            }

            &.HeaderSubMenuItemContainer {
              .HeaderMenuItem {
                .HeaderMenuLink,
                .HeaderSubMenuDropdownIcon {
                  height: 100%;
                }

                .HeaderMenuLink {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
              }
            }

            &:not(.HeaderSubMenuItemContainer) {
              .HeaderMenuItem {
                .HeaderMenuLink {
                  height: 100%;
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
              }
            }
          }
        }

        .HeaderMobileMenuButtonsContainer {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 36px;
          width: 100%;

          .HeaderMobileMenuButton {
            width: 50%;
            margin-left: 18px;
            margin-right: 18px;

            &:first-child {
              margin-left: 0px;
              margin-right: 18px;
            }

            &:last-child {
              margin-left: 18px;
              margin-right: 0px;
            }
          }
        }

        .HeaderMobileMenuButtonContainerB2bEcommerce {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 36px;
          width: 100%;

          .HeaderMobileMenuButton {
            @include for-tablet-only {
              width: 90% !important;
            }

            @include for-mobile-only {
              width: 95% !important;
            }
            
            margin-left: 18px;
            margin-right: 18px;

            &:first-child {
              margin-left: 0px;
              margin-right: 18px;
            }

            &:last-child {
              margin-left: 18px;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  @include for-tablet-only {
    height: $headerCollapsedHeight;

    .Header {
      .HeaderLogo {
        .HeaderLogoImage {
          height: 42px;
          max-width: 128px;
        }
      }
    }

    &.HeaderFixed {
      height: $headerCollapsedHeight;
    }

    &.HeaderMenuOpen {
      .HeaderMobileMenu {
        height: calc(100% - $headerCollapsedHeight);

        .HeaderMenuItemList {
          .HeaderMenuItemContainer {
            .HeaderMenuItem {
              .HeaderMenuLink {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  @include for-mobile-only {
    height: $headerCollapsedHeight;

    .Header {
      .HeaderLogo {
        .HeaderLogoImage {
          height: 42px;
          max-width: 128px;
        }
      }
    }

    &.HeaderMenuOpen {
      .HeaderMobileMenu {
        .HeaderMenuItemList {
          .HeaderMenuItemContainer {
            .HeaderMenuItem {
              width: 100%;
            }
          }
        }
      }
    }

    &.HeaderFixed {
      height: $headerCollapsedHeight;

      .Header {
        height: $headerCollapsedHeight;
      }

      &.HeaderMenuOpen {
        .HeaderMobileMenu {
          height: calc(100% - $headerCollapsedHeight);
        }
      }
    }

    &.HeaderMenuOpen {
      .HeaderMobileMenu {
        height: calc(100% - $headerCollapsedHeight);

        .HeaderMenuItemList {
          .HeaderMenuItemContainer {
            .HeaderMenuItem {
              .HeaderMenuLink {
                font-size: 18px;
              }
            }
          }
        }

        .HeaderMobileMenuButtonsContainer {
          .HeaderMobileMenuButton {
            margin-left: 12px;
            margin-right: 12px;

            &:first-child {
              margin-right: 12px;
            }

            &:last-child {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }

  @supports (backdrop-filter: none) {
    &.HeaderFixed {
      .Header {
        .HeaderMenu {
          .HeaderMenuItemList {
            .HeaderMenuItemContainer {
              &.HeaderSubMenuDropdownOpen {
                .HeaderSubMenuDropdown {
                  background-color: rgba(255, 255, 255, 0.75);
                  backdrop-filter: blur(25px);

                  // @media (prefers-color-scheme: dark) {
                  //   background-color: rgba(0, 0, 0, 0.75);
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}
