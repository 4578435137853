@import '../../../styles/variables';

.CardContainer {
  display: flex;

  &.CardContainerVertical {
    flex-direction: column;
  }

  &.CardContainerHorizontal {
    flex-direction: row;

    .CardImageContainer {
      padding-right: 36px;
    }

    .CardContent {
      padding-left: 36px;
      margin-top: 0px;
    }
  }

  &.CardContainerAlignItemsFlexStart {
    align-items: flex-start;
  }

  &.CardContainerJustifyContentFlexStart {
    justify-content: flex-start;
  }

  &.CardContainerAlignItemsFlexEnd {
    align-items: flex-end;
  }

  &.CardContainerJustifyContentFlexEnd {
    justify-content: flex-end;
  }

  &.CardContainerAlignItemsCenter {
    align-items: center;
  }

  &.CardContainerJustifyContentCenter {
    justify-content: center;
  }

  &.CardContainerAlignItemsSpaceAround {
    align-items: space-around;
  }

  &.CardContainerJustifyContentSpaceAround {
    justify-content: space-around;
  }

  &.CardContainerAlignItemsSpaceBetween {
    align-items: space-between;
  }

  &.CardContainerJustifyContentSpaceBetween {
    justify-content: space-between;
  }

  &.CardContainerAlignItemsSpaceEvenly {
    align-items: space-evenly;
  }

  &.CardContainerJustifyContentSpaceEvenly {
    justify-content: space-evenly;
  }

  &.CardContainerAlignItemsStretch {
    align-items: stretch;
  }

  &.CardContainerJustifyContentStretch {
    justify-content: stretch;
  }

  .CardImageContainer {
    margin: 0px auto;

    .CardImage {
      display: block;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    .CardVideoContainer {
      .CardVideo {
        border: none;
        outline: none;
        box-shadow: none;
        display: block;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;

        iframe {
          border: none;
          outline: none;
          box-shadow: none;
          display: block;
          height: auto;
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .CardImageContainer+.CardContent {
    margin-top: 24px;
  }

  .CardContent {
    .CardTitle {
      font-size: 26px;
      font-weight: 700;
      color: var(--green);
    }

    .CardDescription {
      font-size: 18px;
      font-weight: 400;
      color: var(--black);
      white-space: pre-wrap;
    }

    .CardTitle+.CardDescription {
      margin-top: 8px;
    }
  }

  @include for-mobile-only {
    &.CardContainerRightMobile {
      flex-direction: column-reverse;

      .CardImageContainer+.CardContent {
        margin-top: 0px;
      }

      .CardImageContainer:not(:only-child) {
        margin-top: 24px;
      }
    }

    &.CardContainerLeftMobile {
      flex-direction: column;
    }

    &.CardContainerHorizontal {
      &.CardContainerRightMobile {
        .CardImageContainer {
          padding-left: 0px;
          padding-right: 0px;
        }

        .CardContent {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      &.CardContainerLeftMobile {
        .CardImageContainer {
          padding-left: 0px;
          padding-right: 0px;
        }

        .CardContent {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }

  @include for-tablet-and-desktop {
    &.CardContainerHorizontal {

      .CardImageContainer,
      .CardContent {
        flex: 1 1 auto;
      }

      &.CardContainerRightDesktop {
        flex-direction: row-reverse;

        .CardImageContainer {
          padding-left: 36px;
          padding-right: 0px;
        }

        .CardContent {
          padding-left: 0px;
          padding-right: 36px;
        }
      }

      &.CardContainerLeftDesktop {
        flex-direction: row;

        .CardImageContainer {
          padding-left: 0px;
          padding-right: 36px;
        }

        .CardContent {
          padding-left: 36px;
          padding-right: 0px;
        }
      }

      .CardImageContainer+.CardContent {
        margin-top: 0px;
      }
    }
  }

  @include for-tablet-only {
    &.CardContainerHorizontal {
      &.CardContainerRightDesktop {
        .CardImageContainer {
          padding-left: 24px;
        }

        .CardContent {
          padding-right: 24px;
        }
      }

      &.CardContainerLeftDesktop {
        .CardImageContainer {
          padding-right: 24px;
        }

        .CardContent {
          padding-left: 24px;
        }
      }
    }
  }
}
