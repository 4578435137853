@import '../../../styles/variables';

.LinksContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 36px;
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;

  .LinkContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ExtraIcon {
      margin-right: 18px;
      display: block;
      height: 24px;
      min-height: 24px;
      width: 24px;
      min-width: 24px;

      &.ExtraIconLeft {
        order: 0;
      }

      &.ExtraIconRight {
        order: 2;
        margin-right: 0px;
        margin-left: 12px;
      }
    }

    .Link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: var(--green);
      padding-bottom: 4px;

      .LinkIcon {
        height: 24px;
        min-height: 24px;
        width: 36px;
        min-width: 24px;
        margin-left: 6px;
        fill: var(--green);

        path {
          fill: inherit;
        }
      }

      &.LinkIconRight {
        flex-direction: row;
      }

      &.LinkIconLeft {
        flex-direction: row-reverse;

        .LinkIcon {
          margin-left: 0px;
          margin-right: 6px;
        }
      }

      &.HideUnderline {
        border-bottom: none;
      }

      &.ShowUnderline {
        border-bottom: 1px solid var(--green);
      }
    }
  }

  &.LinksContainerHorizontal {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .LinkContainer {
      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }

  &.LinksContainerVertical {
    flex-direction: column;

    .LinkContainer {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  @include for-mobile-only {
    .LinkContainer {
      .ExtraIcon {
        margin-right: 12px;
      }

      .Link {
        .LinkIcon {
          margin-left: 0px;
        }
      }
    }
  }
}
