@import "../../../../styles/variables";

.Container {
  padding: 0px 36px 12px 36px;
  max-width: $largeDesktopMediaQueryMinWidth;
  margin: -36px auto -36px auto;

  .Row {
    display: flex;
    flex-wrap: wrap;
    margin: 18px -36px;

    .Column {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      padding: 18px 36px;

      .PageLinks {
        margin-top: 12px;
      }

      &:first-child {
        .PageLinks {
          margin-top: 6px;
        }
      }
    }
  }

  @include for-desktop-only {
    .Row {
      .Column {
        width: calc(100% / 3);

        .PageLinks {
          a {
            > span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @include for-mobile-and-tablet {
    padding: 0px 0px 24px 0px;
    margin-top: 0px;

    .Row {
      margin-top: 0px;
    }
  }

  @include for-tablet-only {
    .Row {
      .Column {
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          padding-top: 0px;
        }

        .PageLinks {
          a {
            >span {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @include for-mobile-only {
    width: 100%;

    .Row {
      flex-direction: column;
      margin-left: 0px;
      margin-right: 0px;

      .Column {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;

        &:nth-child(1) {
          padding-top: 0px;
        }

        .PageLinks {
          a {
            >span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
